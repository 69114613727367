import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Asset/Css/tailwind.css';

const root = ReactDOM.createRoot(
    document.getElementById('content') as HTMLElement
);

root.render(
    <App/>
);