class SystemRepository {
    getOperatingSystem() {
        let operatingSystem = 'Unknown';

        if (window.navigator.userAgent.indexOf('Win') !== -1) {
            operatingSystem = 'windows';
        }
        if (window.navigator.userAgent.indexOf('Mac') !== -1) {
            operatingSystem = 'macos';
        }
        if (window.navigator.userAgent.indexOf('X11') !== -1) {
            operatingSystem = 'unix';
        }
        if (window.navigator.userAgent.indexOf('Linux') !== -1) {
            operatingSystem = 'linux';
        }

        return operatingSystem;
    }

    getDeviceKey(): string | null {
        return localStorage.getItem('_DK');
    }

    getUserAgent(): string {
        return navigator.userAgent;
    }

    isMobileDevice = (): boolean => {
        const regExs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
        return regExs.some((b) => this.getUserAgent().match(b))
    }

    isTabletDevice = (): boolean => {
        const regExs = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
        return regExs.test(this.getUserAgent().toLowerCase())
    }

    isDesktopDevice = (): boolean => !this.isMobileDevice() && !this.isTabletDevice()

    getDeviceType = (): string => {
        switch (true) {
            case this.isMobileDevice():
                return 'mobile';
            case this.isDesktopDevice():
                return 'desktop';
            case this.isTabletDevice():
                return 'tablet';
            default:
                return 'unknown';
        }
    }
}

export default SystemRepository;