import React from "react";
import NextButton from "../../Component/NextButton";
import {Form, message} from "antd";
import PasswordField from "../../Component/Field/PasswordField";
import {PasswordForm} from "../../Interface/Form/Authenticate";
import {IRegistration} from "../../Interface/Model/IRegistration";
import {Registrator as RegistratorService} from "../../Service/Registrator";
import {useSearchParams} from "react-router-dom";
import {UrlHelper} from "../../Helper/UrlHelper";

const Password = ({registration}: { registration: IRegistration }) => {
    const [queryParameters] = useSearchParams();

    const onFinish = async (formData: PasswordForm) => {
        registration.setLoading(true);

        registration.password = formData.password;

        (new RegistratorService())
            .register(registration.formData!)
            .then(response => {
                window.location.href = UrlHelper.UrlWithParams(`/auth`, queryParameters);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                registration.setLoading(false);
            });
    }

    return (
        <Form layout='vertical' onFinish={onFinish}>
            <PasswordField></PasswordField>

            <div className="flex justify-center mt-9 mb-6">
                <NextButton>Next</NextButton>
            </div>
        </Form>
    );
}

export default Password;