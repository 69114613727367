import axios from "axios";
import {NotFoundHttpException} from "../Exception/NotFoundHttpException";
import {UnauthorizedHttpException} from "../Exception/UnauthorizedHttpException";
import {UnprocessableEntityHttpException} from "../Exception/UnprocessableEntityHttpException";
import {ForbiddenHttpException} from "../Exception/ForbiddenHttpException";

export const HttpClient = axios.create({
    baseURL: 'https://auth.uzdevid.com/api/auth',
    headers: {
        'Accept-Language': 'uz-UZ'
    },
    withCredentials: true
});

export class Client {
    static async get(url: string, config?: any) {
        return await HttpClient.get(url, config);
    }

    static async post(url: string, data: any, config?: any) {
        return await HttpClient.post(url, data, config).catch(error => {
            throw ExceptionHandler.handle(error);
        });
    }
}

class ExceptionHandler {
    static handle(error: any) {
        let message: string = error.response.data.body.message;

        switch (error.response.status) {
            case 400:
                break;
            case 401:
                return new UnauthorizedHttpException(message);
            case 403:
                return new ForbiddenHttpException(message);
            case 404:
                return new NotFoundHttpException(message);
            case 422:
                return new UnprocessableEntityHttpException(message, error.response.data.body.errors)
            case 500:
                break;
            default:
                break;
        }
    }
}