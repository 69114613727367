import React from "react";
import {Form, Input as AntInput} from "antd";

const Input = ({label, type, name, placeholder, autoFocus = false, rules = []}: { label: string, type: string, name: string, placeholder?: string, autoFocus?: boolean, rules?: object[] }) => {
    return (
        <Form.Item name={name} label={label} rules={rules}>
            <AntInput type={type} placeholder={placeholder} autoFocus={autoFocus} size="large"/>
        </Form.Item>
    );
}

export default Input;