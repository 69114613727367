import React from "react";
import {Button} from "antd";

const NextButton = ({children}: { children: string }) => {
    return (
        <Button
            type={"primary"}
            htmlType={"submit"}
            className="inline-flex items-center justify-center rounded py-1.5 px-3 text-sm font-semibold transition-all w-full">
            {children}
        </Button>
    );
}

export default NextButton;