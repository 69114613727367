import {
    AuthenticateFormData,
    AuthMethod,
    EmailBasedAuthFormData,
    IAuthenticate,
    PhoneBasedAuthFormData
} from "../Interface/Model/IAuthenticate";
import {IVerification} from "../Interface/Model/IVerification";
import {verify} from "node:crypto";

export class Authenticate implements IAuthenticate {
    authSource: string = 'email';

    verify: IVerification | null = null;
    authMethod: AuthMethod | null = null;

    private readonly _setStep: Function;
    private readonly _setLoading: Function;

    constructor(setStep: Function, setLoading: Function) {
        this._setStep = setStep;
        this._setLoading = setLoading;
    }

    formData: AuthenticateFormData | null = null;

    changeAuthSource = (source: string) => {
        this.authSource = source;
    };

    setStep = (step: string) => {
        this._setStep(step);
    };

    setLoading = (loading: boolean) => {
        this._setLoading(loading);
    };

    setAuthMethod(method: AuthMethod): void {
        this.authMethod = method;
    }

    setVerify(verify: IVerification): void {
        this.verify = verify;
    }

    mustVerify(): boolean {
        return this.verify !== null;
    }
}