import {
    EmailBasedIdentityForm, IdentityFormData,
    IRegistration,
    PhoneBasedIdentityForm,
    RegistrationFormData,
    UserPersonalFormData
} from "../Interface/Model/IRegistration";
import {IVerification} from "../Interface/Model/IVerification";

export class Registration implements IRegistration {
    private readonly _setStep: Function;
    private readonly _setLoading: Function;

    constructor(setStep: Function, setLoading: Function) {
        this._setStep = setStep;
        this._setLoading = setLoading;
    }

    formData: RegistrationFormData | null = null;
    verify: IVerification | null = null;

    mustVerify(): boolean {
        return this.verify !== null;
    }

    setLoading(loading: boolean): void {
        this._setLoading(loading);
    }

    setStep(step: string): void {
        this._setStep(step);
    }

    setVerify(verify: IVerification): void {
        this.verify = verify;
    }

    set identityForm(formData: IdentityFormData) {
        if (this.formData === null) {
            this.formData = {Identity: formData, User: null}
        }

        this.formData = {...this.formData, Identity: formData}
    }

    set password(password: string) {
        if (this.formData === null) {
            this.formData = {Identity: null, User: null}
        }

        this.formData.Identity!.password = password;
    }

    set userForm(formData: UserPersonalFormData) {
        if (this.formData === null) {
            this.formData = {Identity: null, User: formData}
        }

        this.formData = {...this.formData, User: formData}
    }
}