import {Flex, Spin} from "antd";
import React from "react";

const FormSide = ({children, isLoading}: { children: any, isLoading: boolean }) => {
    return (
        <div className="px-6 py-8">
            <Spin spinning={isLoading} delay={0}>
                <Flex vertical justify={'space-between'} style={{height: '420px'}}>
                    {children}
                </Flex>
            </Spin>
        </div>
    );
}

export default FormSide;