import React, {useEffect, useState} from "react";
import FormSide from "../Component/FormSide";
import {HttpClient} from "../Http/HttpClient";
import {Button, Flex, Input, message, Result, Skeleton, Typography} from "antd";
import {useSearchParams} from "react-router-dom";
import Header from "../Component/Header";

const GoTo = () => {
    const formActionUrl = "https://auth.uzdevid.com/api/auth/oauth/redirect";

    let service: Service | null;
    let setService: any;

    [service, setService] = useState(null);

    const [queryParameters] = useSearchParams();

    useEffect(() => {
        HttpClient.get(`/${serviceId()}/service/info`).then(response => {
            setService(response.data.body.Service);
        }).catch(error => {
            message.error(error.response.data.body.message);
        })
    }, []);

    const identityId = (): string => {
        return queryParameters.get('id') as string;
    }

    const serviceId = (): string => {
        return queryParameters.get('client_id') ?? 'f97e4bc8-c9d3-4849-b90c-6fe0254414cf';
    }

    const backUrl = (): string => {
        return queryParameters.get('redirect_uri') ?? 'https://id.uzdevid.com';
    }

    const renderContent = () => {
        if (service == null) {
            return (
                <Result
                    status="warning"
                    title={<Skeleton active/>}
                    extra={
                        <Flex gap={'middle'}>
                            <Skeleton.Button active={false} size={'default'} shape={'default'} block={false}/>
                        </Flex>
                    }
                />
            );
        }

        return (
            <form action={formActionUrl} method='POST'>
                <Result
                    status="warning"
                    title={<Typography.Text><b>{service.name}</b> tizimiga kirishni tasdiqlaysizmi?</Typography.Text>}
                    extra={<Button htmlType='submit'>Yes, i confirm</Button>}
                />

                <Input type='hidden' name='identity_id' value={identityId()}></Input>
                <Input type='hidden' name='service_id' value={serviceId()}></Input>
                <Input type='hidden' name='back_url' value={backUrl()}></Input>
            </form>
        );
    }

    return (
        <FormSide isLoading={false}>
            <Header title={'Confirm'}></Header>

            {renderContent()}

            <div></div>
        </FormSide>
    );
}

export default GoTo;