import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Recovery from "./Page/Recovery";
import Register from "./Page/Register";
import Auth from "./Page/Auth";
import {HttpClient} from "./Http/HttpClient";
import Device from "./Form/Device";
import Init from "./Page/Init";
import DefaultTheme from "./Component/DefaultTheme";
import GoTo from "./Page/GoTo";
import Confirm from "./Page/Confirm";

const App = () => {
    useEffect(() => {
        registerDevice();
    });

    const registerDevice = async () => {
        let device = new Device();

        await HttpClient.post('/device/register', {DeviceRegister: device});
    }

    return (
        <DefaultTheme>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Init/>}/>
                    <Route path="/authorize" element={<Init/>}/>

                    <Route path="/go" element={<GoTo/>}/>

                    <Route path="/auth" element={<Auth/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/recovery" element={<Recovery/>}/>
                    <Route path="/verify" element={<Confirm/>}/>
                </Routes>
            </BrowserRouter>
        </DefaultTheme>
    );
}

export default App;
