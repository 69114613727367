import React from "react";
import {Flex} from "antd";

const Header = ({title}: { title: string }) => {
    return (
        <Flex justify='space-between' align='center'>
            <h5 className="text-xl font-bold text-gray-700">{title}</h5>
        </Flex>
    );
}

export default Header;