export class UrlHelper {
    static saveParams = ['client_id', 'redirect_uri'];


    static UrlWithParams = (newPath: string, urlParams: URLSearchParams): string => {
        let nextParams = '';

        this.saveParams.forEach((name) => {
            if (urlParams.has(name)) {
                localStorage.setItem(name, urlParams.get(name) as string);

                if (nextParams.length === 0) {
                    nextParams += `${name}=${urlParams.get(name)}`;
                } else {
                    nextParams += `&${name}=${urlParams.get(name)}`;
                }
            }
        });

        if (newPath.includes('?')) {
            return newPath + '&' + nextParams;
        } else {
            return newPath + '?' + nextParams;
        }
    }
}