import React from "react";
import {Avatar, Button, Flex, List} from "antd";
import {RightOutlined, UserOutlined} from "@ant-design/icons";
import {UrlHelper} from "../Helper/UrlHelper";

const Session = ({regularSession, queryParameters}: {
    regularSession: RegularSession,
    queryParameters: URLSearchParams
}) => {
    const goTo = () => {
        window.location.href = UrlHelper.UrlWithParams(`/go?id=${regularSession.identity.id}`, queryParameters);
    }

    return (
        <Flex justify='space-between' align='center'>
            <List.Item>
                <List.Item.Meta
                    avatar={<Avatar size="large" icon={<UserOutlined/>}/>}
                    title={`${regularSession.user.f_name} ${regularSession.user.l_name}`}
                    description={regularSession.identity.email ?? regularSession.identity.phone}
                />
            </List.Item>

            <Button icon={<RightOutlined/>} onClick={goTo}/>
        </Flex>
    );
}

export default Session;