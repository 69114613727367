import React, {useMemo, useState} from "react";
import Header from "../Component/Header";
import Login from "./Register/Login";
import Password from "./Register/Password";
import FullName from "./Register/FullName";
import FormSide from "../Component/FormSide";
import {Divider, Typography} from "antd";
import {IRegistration} from "../Interface/Model/IRegistration";
import {Registration} from "../Model/Registration";
import {Link, useSearchParams} from "react-router-dom";
import {UrlHelper} from "../Helper/UrlHelper";

const Register = () => {
    const [step, setStep] = useState(FullName.name);
    const [isLoading, setLoading] = useState(false);

    const [queryParameters] = useSearchParams();

    const registration: IRegistration = useMemo(() => new Registration(setStep, setLoading), []);

    const renderStep = () => {
        switch (step) {
            case FullName.name:
                return (<FullName registration={registration}></FullName>)
            case Login.name:
                return (<Login registration={registration}></Login>);
            case Password.name:
                return (<Password registration={registration}></Password>)
        }
    }

    return (
        <FormSide isLoading={isLoading}>
            <Header title="Register"></Header>

            <div className='mt-9'>
                {renderStep()}
            </div>

            <div>
                <Divider plain><Typography.Text type={'secondary'}>Or</Typography.Text></Divider>

                <p className="text-gray-500 text-center">Already have an account ?
                    <Link to={UrlHelper.UrlWithParams('/auth', queryParameters)}
                          className="text-sky-500 ms-1"><b>Login</b></Link>
                </p>
            </div>
        </FormSide>
    );
}

export default Register;