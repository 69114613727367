import React from "react";
import ByEmail from "./Source/ByEmail";
import {IAuthenticate} from "../../Interface/Model/IAuthenticate";

const Login = ({authenticate}: { authenticate: IAuthenticate }) => {
    return (
        <ByEmail authenticate={authenticate}></ByEmail>
    );
}

export default Login;