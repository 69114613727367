import React from "react";
import {Form, message} from "antd";
import Password from "../Password";
import EmailField from "../../../Component/Field/EmailField";
import NextButton from "../../../Component/NextButton";
import {Registrator as RegistratorService} from "../../../Service/Registrator";
import {EmailForm} from "../../../Interface/Form/Authenticate";
import {IRegistration} from "../../../Interface/Model/IRegistration";

const ByEmail = ({registration}: { registration: IRegistration }) => {
    const onFinish = async (formData: EmailForm) => {
        registration.setLoading(true);

        (new RegistratorService())
            .checkEmail(formData)
            .then(response => {
                registration.identityForm = {grant_type: 'email', login: formData.email};

                registration.setStep(Password.name);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                registration.setLoading(false);
            });
    }

    return (
        <Form layout='vertical' onFinish={onFinish}>
            <EmailField></EmailField>

            <div className="flex justify-center mt-9 mb-6">
                <NextButton>Next</NextButton>
            </div>
        </Form>
    );
}

export default ByEmail;