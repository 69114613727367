import React from "react";
import Header from "../Component/Header";
import EmailField from "../Component/Field/EmailField";

const Recovery = () => {
    return (
        <div className="px-6 py-8">
            <Header title="Recovery"></Header>

            <EmailField></EmailField>

            <div className="flex justify-center mb-6">
                <button
                    className="inline-flex items-center justify-center rounded py-1.5 px-3 text-sm font-semibold transition-all w-full text-white bg-sky-500"> Send
                    Reset Link
                </button>
            </div>

            <div className="flex items-center my-6">
                <div className="flex-auto mt-px border-t border-dashed border-gray-200"></div>
                <div className="mx-4 text-secondary">Or</div>
                <div className="flex-auto mt-px border-t border-dashed border-gray-200"></div>
            </div>

            <p className="text-gray-500 text-center mb-8">I remember my password ?
                <a href="/" className="text-sky-500 ms-1">
                    <b>Login</b>
                </a>
            </p>

            <p className="text-gray-500 text-center">New account ?
                <a href="/register" className="text-sky-500 ms-1">
                    <b>Register Now</b>
                </a>
            </p>
        </div>
    );
}

export default Recovery;