import React, {useMemo, useState} from "react";
import Header from "../Component/Header";
import Login from "./Auth/Login";
import Password from "./Auth/Password";
import {Divider, Typography} from "antd";
import FormSide from "../Component/FormSide";
import {Authenticate} from "../Model/Authenticate";
import {IAuthenticate} from "../Interface/Model/IAuthenticate";
import {Link, useSearchParams} from "react-router-dom";
import {UrlHelper} from "../Helper/UrlHelper";

const Auth = () => {
    const [step, setStep] = useState(Login.name);
    const [isLoading, setLoading] = useState(false);

    const [queryParameters] = useSearchParams();

    const authenticate: IAuthenticate = useMemo(() => new Authenticate(setStep, setLoading), []);

    const renderStep = () => {
        switch (step) {
            case Login.name:
                return (<Login authenticate={authenticate}></Login>);
            case Password.name:
                return (<Password authenticate={authenticate}></Password>)
        }
    }

    return (
        <FormSide isLoading={isLoading}>
            <Header title="Login"></Header>

            <div>
                {renderStep()}
            </div>

            <div>
                <Divider plain><Typography.Text type={'secondary'}>Or</Typography.Text></Divider>

                <p className="text-gray-500 text-center">Don't have an account ?
                    <Link to={UrlHelper.UrlWithParams('/register', queryParameters)}
                          className="text-sky-500 ms-1"><b>Register</b></Link>
                </p>
            </div>
        </FormSide>
    );
}

export default Auth;