import React, {useMemo, useState} from "react";
import NextButton from "../Component/NextButton";
import {Button, Flex, Form, Input, message, Statistic, Tooltip, Typography} from "antd";
import {Verificator} from "../Service/Verificator";
import {RedoOutlined} from "@ant-design/icons";
import Login from "./Auth/Login";
import {useSearchParams} from "react-router-dom";
import {IOtp} from "../Interface/Model/IVerification";
import {OtpForm} from "../Interface/Form/Authenticate";
import Header from "../Component/Header";
import FormSide from "../Component/FormSide";
import {UrlHelper} from "../Helper/UrlHelper";

const {Countdown} = Statistic;

const Confirm = () => {
    const [step, setStep] = useState(Login.name);
    const [isLoading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const [otpDied, setOtpDied] = useState(false);
    const [otpValue, setOtpValue] = useState('')

    const [queryParameters] = useSearchParams();

    let otp: IOtp = useMemo(() => {
        // @ts-ignore
        return JSON.parse(atob(queryParameters.get('token')));
    }, []);

    const resendOtp = () => {
        setLoading(true);

        (new Verificator()).resend(otp.id)
            .then(response => {
                let payload = {
                    id: response.headers.get('Otp-Id'),
                    digits: +response.headers.get('Otp-Digits'),
                    lifetime: +response.headers.get('Otp-Lifetime')
                }

                let token = btoa(JSON.stringify(payload));

                window.location.href = UrlHelper.UrlWithParams(`/verify?token=${response.data.body.Identity.id}`, queryParameters);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onFinish = async (formData: OtpForm) => {
        setLoading(true);

        (new Verificator()).verify(otp.id, otpValue)
            .then(response => {
                window.location.href = UrlHelper.UrlWithParams(`/go?id=${response.data.body.Identity.id}`, queryParameters);
            })
            .catch(error => {
                message.error(error?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onChange = (text: string) => {
        setOtpValue(text)
    }
    
    const lastItem = () => {
        if (otpDied) {
            return (
                <Tooltip title="Resend OTP">
                    <Button type="dashed" icon={<RedoOutlined/>} onClick={resendOtp}/>
                </Tooltip>
            )
        }

        return (
            <Countdown
                value={otp!.lifetime}
                format="mm:ss"
                valueStyle={{fontSize: '16px'}}
                onFinish={countDownFinish}
            />
        );
    }

    const countDownFinish = () => {
        setOtpDied(true);
    }

    return (
        <FormSide isLoading={isLoading}>
            <Header title="Confirm"></Header>

            <Flex vertical justify={"center"} gap={'middle'} align={"center"}>
                <Typography.Text>
                    Enter the OTP code sent to your email address
                </Typography.Text>

                <Form form={form} layout={"vertical"} onFinish={onFinish}>
                    <Form.Item name="code">
                        <Flex justify={"center"} gap={"small"} align={"center"}>
                            <Input.OTP
                                autoFocus={true}
                                onChange={onChange}
                                inputMode='numeric'
                                length={otp!.digits}
                                value={otpValue}
                                mask="*"
                            >
                            </Input.OTP>
                            {lastItem()}
                        </Flex>
                    </Form.Item>

                    <div className="flex justify-center mt-9 mb-6">
                        <NextButton>Confirm</NextButton>
                    </div>
                </Form>
            </Flex>

            <div></div>
        </FormSide>
    );
}

export default Confirm;