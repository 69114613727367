import React from "react";
import {IRegistration} from "../../Interface/Model/IRegistration";
import ByEmail from "./Source/ByEmail";

const Login = ({registration}: { registration: IRegistration }) => {
    return (
        <ByEmail registration={registration}></ByEmail>
    );
}

export default Login;