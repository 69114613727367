import React from "react";
import NextButton from "../../Component/NextButton";
import {Form, message} from "antd";
import PasswordField from "../../Component/Field/PasswordField";
import {Authenticator} from "../../Service/Authenticator";
import {IAuthenticate} from "../../Interface/Model/IAuthenticate";
import {PasswordForm} from "../../Interface/Form/Authenticate";
import {UrlHelper} from "../../Helper/UrlHelper";
import {useSearchParams} from "react-router-dom";

const Password = ({authenticate}: { authenticate: IAuthenticate }) => {
    const [queryParameters] = useSearchParams();

    const onFinish = async (formData: PasswordForm) => {
        authenticate.setLoading(true);

        let serviceId = queryParameters.get('client_id') as string;

        (new Authenticator).authenticate(serviceId, authenticate, formData)
            .then(response => {
                if (!authenticate.mustVerify()) {
                    window.location.href = UrlHelper.UrlWithParams(`/go?id=${response.data.body.Identity.id}`, queryParameters);
                    return;
                }

                let payload = {
                    id: response.headers.get('Otp-Id'),
                    digits: +response.headers.get('Otp-Digits'),
                    lifetime: +response.headers.get('Otp-Lifetime'),
                }

                let token = btoa(JSON.stringify(payload));

                window.location.href = UrlHelper.UrlWithParams(`/verify?token=${token}`, queryParameters);
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                authenticate.setLoading(false);
            });
    }

    return (
        <Form layout='vertical' onFinish={onFinish}>
            <input
                type={"hidden"}
                autoComplete={'login'}
                value={authenticate.formData?.login}
            />

            <PasswordField></PasswordField>

            <div className="flex justify-center mt-9 mb-6">
                <NextButton>Sign in</NextButton>
            </div>
        </Form>
    );
}

export default Password;