import React from "react";
import Input from "../Input/Input";

const PasswordField = () => {
    const rules = [
        {required: true},
        {type: 'password'},
    ];

    return (
        <Input type="password" name="password" placeholder="Enter your password" label='Password' autoFocus={true} rules={rules}></Input>
    );
}

export default PasswordField;