import React from "react";
import Input from "../../Component/Input/Input";
import NextButton from "../../Component/NextButton";
import {Form} from "antd";
import Login from "./Login";
import {IRegistration} from "../../Interface/Model/IRegistration";

const FullName = ({registration}: { registration: IRegistration }) => {
    const rules = [
        {required: true},
        {type: 'text'},
    ];

    const onFinish = async (formData: RegisterUser) => {
        registration.userForm = formData;

        registration.setStep(Login.name);
    }

    return (
        <Form layout="vertical" onFinish={onFinish}>
            <Input
                type="text"
                name="f_name"
                placeholder="Enter your first name"
                autoFocus={true}
                label='First name'
                rules={rules}
            ></Input>

            <Input
                type="text"
                name="l_name"
                placeholder="Enter your last name"
                label='Last name'
                rules={rules}
            ></Input>

            <div className="flex justify-center mt-9 mb-6">
                <NextButton>Register</NextButton>
            </div>
        </Form>
    );
}

export default FullName;