import {Client} from "../Http/HttpClient";
import {IAuthenticate} from "../Interface/Model/IAuthenticate";
import {Verification} from "../Model/Verification";
import {EmailForm, PasswordForm, PhoneForm} from "../Interface/Form/Authenticate";

export class Authenticator {
    getAuthMethod = async (serviceId: string, authenticate: IAuthenticate, formData: EmailForm | PhoneForm) => {
        if ('phone' in formData) {
            authenticate.formData = {grant_type: 'phone', login: formData.code + formData.phone, password: null}
        } else {
            authenticate.formData = {grant_type: 'email', login: formData.email, password: null}
        }

        return Client.post(`/${serviceId}/authenticate/auth-method`, {Authenticate: authenticate.formData});
    }

    authenticate = async (serviceId: string, authenticate: IAuthenticate, formData: PasswordForm) => {
        authenticate.formData!.password = formData.password

        return await Client.post(`/${serviceId}/authenticate/auth`, {Authenticate: authenticate.formData})
            .then((response: any) => {
                if (response.headers.has('Otp-Id')) {
                    authenticate.setVerify(new Verification(
                        response.headers.get('Otp-Id'),
                        +response.headers.get('Otp-Digits'),
                        +response.headers.get('Otp-Lifetime')
                    ));
                }

                return response;
            });
    }
}