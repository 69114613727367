import React from "react";
import {Form, message} from "antd";
import Password from "../Password";
import EmailField from "../../../Component/Field/EmailField";
import NextButton from "../../../Component/NextButton";
import {Authenticator} from "../../../Service/Authenticator";
import {EmailForm} from "../../../Interface/Form/Authenticate";
import {IAuthenticate} from "../../../Interface/Model/IAuthenticate";
import {UrlHelper} from "../../../Helper/UrlHelper";
import {useSearchParams} from "react-router-dom";

const ByEmail = ({authenticate}: { authenticate: IAuthenticate }) => {
    const [queryParameters] = useSearchParams();

    authenticate.changeAuthSource('email');

    const onFinish = async (formData: EmailForm) => {
        authenticate.setLoading(true);

        let serviceId = queryParameters.get('client_id') as string;

        (new Authenticator).getAuthMethod(serviceId, authenticate, formData)
            .then((response: any) => {
                authenticate.setAuthMethod(response.data.body.AuthMethod);

                if (authenticate.authMethod!.grant_type === 'password') {
                    authenticate.setStep(Password.name);
                } else if (authenticate.authMethod!.grant_type === 'otp') {
                    let payload = {
                        id: response.headers.get('Otp-Id'),
                        digits: +response.headers.get('Otp-Digits'),
                        lifetime: +response.headers.get('Otp-Lifetime'),
                    }

                    let token = btoa(JSON.stringify(payload));

                    window.location.href = UrlHelper.UrlWithParams(`/verify?token=${token}`, queryParameters);
                }
            })
            .catch(error => {
                message.error(error.message);
            })
            .finally(() => {
                authenticate.setLoading(false);
            });
    }

    return (
        <Form layout='vertical' onFinish={onFinish}>
            <EmailField></EmailField>

            <div className="flex justify-center mt-9 mb-6">
                <NextButton>Next</NextButton>
            </div>
        </Form>
    );
}

export default ByEmail;