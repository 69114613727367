import {Client} from "../Http/HttpClient";
import {EmailForm} from "../Interface/Form/Authenticate";
import {RegistrationFormData} from "../Interface/Model/IRegistration";

export class Registrator {
    checkEmail = (formData: EmailForm) => {
        return Client.post('/register/check-login', {CheckEmail: formData});
    }

    register = (formData: RegistrationFormData) => {
        return Client.post('/register/register', {Registration: formData});
    }
}