import React from "react";
import Input from "../Input/Input";

const EmailField = () => {
    const rules = [
        {required: true},
        {type: 'email'},
        {type: 'string', max: 255}
    ];

    return (
        <Input type="email" name="email" placeholder="Enter your email" label='Email address' autoFocus={true} rules={rules}></Input>
    );
}

export default EmailField;