import SystemRepository from "../Repository/SystemRepository";
import Random from "../Helper/Random";

class Device {
    key: string;
    name: string;
    os: string;
    type: string;

    constructor() {
        let systemRepository = new SystemRepository();

        this.key = systemRepository.getDeviceKey() ?? Random.string(32);

        localStorage.setItem('_DK', this.key);

        this.name = systemRepository.getUserAgent();
        this.os = systemRepository.getOperatingSystem();
        this.type = systemRepository.getDeviceType();
    }
}

export default Device;