import Header from "../Component/Header";
import Session from "../Component/Session";
import React, {useEffect, useState} from "react";
import {HttpClient} from "../Http/HttpClient";

import {Divider, List, Skeleton, Typography} from "antd";
import FormSide from "../Component/FormSide";
import InfiniteScroll from "react-infinite-scroll-component";
import {Link, useSearchParams} from "react-router-dom";
import {UrlHelper} from "../Helper/UrlHelper";

const Init = () => {
    const [sessions, setSessions] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const [queryParameters] = useSearchParams();

    useEffect(() => {
        loadSessions();
    }, []);

    const loadSessions = () => {
        HttpClient.get('/app/sessions').then(response => {
            setSessions(response.data.body.sessions);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = UrlHelper.UrlWithParams('/auth', queryParameters);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <FormSide isLoading={isLoading}>
            <Header title="Choose account"></Header>

            <InfiniteScroll
                dataLength={sessions.length}
                next={loadSessions}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{rows: 1}} active/>}
            >
                <List
                    style={{height: '250px'}}
                    dataSource={sessions}
                    renderItem={(regularSession) => (
                        <Session regularSession={regularSession} queryParameters={queryParameters}></Session>)}
                />
            </InfiniteScroll>

            <div>
                <Divider plain><Typography.Text type={'secondary'}>Or</Typography.Text></Divider>

                <p className="text-gray-500 text-center">
                    <Link to={UrlHelper.UrlWithParams('/auth', queryParameters)} className="text-sky-500 ms-1"><b>Add
                        another account</b></Link>
                </p>
            </div>
        </FormSide>
    );
}

export default Init;