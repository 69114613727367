import {Client} from "../Http/HttpClient";

export class Verificator {
    verify = async (otpId: string, otpCode: string) => {
        let formData = {
            Verify: {
                id: otpId,
                code: otpCode
            }
        }

        return await Client.post('/verify/confirm', formData).then((response: any) => {
            return response;
        });
    }

    resend = async (otpId: string) => {
        let formData = {
            Verify: {
                id: otpId
            }
        }

        return await Client.post('/verify/resend', formData).then((response: any) => {
            return response;
        });
    }
}